import { FormControl, Select, MenuItem, Box, Typography } from "@mui/material";
import p4au from "../assets/game_logos/p4au.png";
import sf3 from "../assets/game_logos/sf3.png";
import kofxv from "../assets/game_logos/kofxv.png";
import umvc3 from "../assets/game_logos/umvc3.png";
import sfv from "../assets/game_logos/sfv.png";
import dnf from "../assets/game_logos/dnf.png";
import abk from "../assets/game_logos/abk.png";
import samsho from "../assets/game_logos/samsho.png";
import sf6 from "../assets/game_logos/sf6.png";
import bbcf from "../assets/game_logos/bbcf.png";
import bbtag from "../assets/game_logos/bbtag.png";
import gbvs from "../assets/game_logos/gbvs.png";
import acpr from "../assets/game_logos/acpr.png";
import xrd from "../assets/game_logos/xrd.png";
import strive from "../assets/game_logos/strive.png";
import unib from "../assets/game_logos/unib.png";
import tfh from "../assets/game_logos/tfh.png";
import scon4 from "../assets/game_logos/scon4.png";
import vsav from "../assets/game_logos/vsav.png";
import f8uc from "../assets/game_logos/f8uc.png";
import d012 from "../assets/game_logos/d012.png";
import tkn8 from "../assets/game_logos/tkn8.png";
import asbr from "../assets/game_logos/asbr.png";
import vf5 from "../assets/game_logos/vf5.png";

export default function GameSelect(props) {
  const game = props.game;
  const games = [
    {
      title: "Akatsuki Blitzkampf",
      alias: "abk",
      img: abk,
    },
    {
      title: "BlazBlue: Central Fiction",
      alias: "bbcf",
      img: bbcf,
    },
    {
      title: "BlazBlue: Cross Tag Battle",
      alias: "bbtag",
      img: bbtag,
    },
    {
      title: "Dissidia 012 Final Fantasy",
      alias: "d012",
      img: d012,
    },
    {
      title: "DNF Duel",
      alias: "dnf",
      img: dnf,
    },
    {
      title: "Fate/Unlimited Codes",
      alias: "f8uc",
      img: f8uc,
    },
    {
      title: "Granblue Fantasy: Versus",
      alias: "gbvs",
      img: gbvs,
    },
    {
      title: "Guilty Gear XX Accent Core",
      alias: "acpr",
      img: acpr,
    },
    {
      title: "Guilty Gear Xrd",
      alias: "xrd",
      img: xrd,
    },
    {
      title: "Guilty Gear -Strive-",
      alias: "strive",
      img: strive,
    },
    {
      title: "JoJo's Bizarre Adventure: ASBR",
      alias: "asbr",
      img: asbr,
    },
    {
      title: "The King of Fighters XV",
      alias: "kofxv",
      img: kofxv,
    },
    {
      title: "Persona 4: Arena Ultimax",
      alias: "p4au",
      img: p4au,
    },
    {
      title: "Samurai Shodown",
      alias: "samsho",
      img: samsho,
    },
    {
      title: "Street Fighter III: 3rd Strike",
      alias: "sf3",
      img: sf3,
    },
    {
      title: "Street Fighter V",
      alias: "sfv",
      img: sfv,
    },
    {
      title: "Street Fighter 6",
      alias: "sf6",
      img: sf6,
    },
    {
      title: "Super Naruto: Clash of Ninja 4",
      alias: "scon4",
      img: scon4,
    },
    {
      title: "Them's Fightin' Herds",
      alias: "tfh",
      img: tfh,
    },
    {
      title: "Tekken 8",
      alias: "tkn8",
      img: tkn8,
    },
    {
      title: "Ultimate Marvel vs Capcom 3",
      alias: "umvc3",
      img: umvc3,
    },
    {
      title: "Under Night In-Birth",
      alias: "unib",
      img: unib,
    },
    {
      title: "Vampire Savior",
      alias: "vsav",
      img: vsav,
    },
    {
      title: "Virtua Fighter 5 R.E.V.O.",
      alias: "vf5",
      img: vf5,
    },
  ];

  const handleChange = (event) => {
    props.onGameChange(event.target.value);
  };

  return (
    <FormControl sx={{ minWidth: 200, textAlign: "center" }}>
      <Select
        value={game}
        label="Game"
        onChange={handleChange}
        onClose={() => {
          setTimeout(() => {
            document.activeElement.blur();
          }, 0);
        }}
        variant="standard"
        displayEmpty
        renderValue={game !== "" ? undefined : () => "Select a Game"}
      >
        {games.map((g) => (
          <MenuItem key={g.alias} value={g.alias}>
            <Box display="flex" justifyContent={"center"} sx={{ height: 69 }}>
              <img
                src={g.img}
                alt={g.title}
                width={"100px"}
                height="auto"
                style={{
                  alignSelf: "center",
                }}
              />
              <Typography sx={{ marginLeft: 1, alignSelf: "center" }}>
                {g.title}
              </Typography>
            </Box>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
