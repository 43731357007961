import { useState } from "react";
import { Fade, Avatar, Menu, MenuItem, Button } from "@mui/material";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { characters } from "../assets/chars";

export default function CharacterSelect(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const char = props.char;
  const open = Boolean(anchorEl);
  const sizes = {
    large: 50,
    medium: 42,
    small: 34,
    medium_small: 38,
  };
  const size = sizes[props.size];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    props.onCharChange(event.target.id || event.target.alt || char);
    setAnchorEl(null);
  };

  return (
    <div>
      <Button variant="text" onClick={handleClick} disabled={!props.game}>
        <Avatar
          aria-controls={open ? "long-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          variant="rounded"
          sx={{
            cursor: "pointer",
            width: size,
            height: size,
          }}
          src={`/character_icons/${props.game}/${char.replace(/[♯?]/, "")}.png`}
        >
          <QuestionMarkIcon fontSize={props.size} />
        </Avatar>
      </Button>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        PaperProps={{
          style: {
            maxHeight: 350,
            minWidth: 250,
          },
        }}
      >
        {!props.hideAny && (
          <MenuItem
            key={"Any"}
            id={"Any"}
            selected={"Any" === char}
            onClick={handleClose}
          >
            <Avatar
              variant="rounded"
              sx={{ width: 33, height: 33, marginRight: 1.5 }}
            >
              <QuestionMarkIcon id={"Any"} />
            </Avatar>
            Any
          </MenuItem>
        )}
        {(characters[props.game] || []).map((option) => (
          <MenuItem
            key={option}
            id={option}
            selected={option === char}
            onClick={handleClose}
          >
            <Avatar
              variant="rounded"
              alt={option}
              src={`/character_icons/${props.game}/${option.replace(
                /[♯?]/,
                ""
              )}.png`}
              sx={{
                width: 33,
                height: 33,
                marginRight: 1.5,
              }}
            />
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
