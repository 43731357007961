export const gameMap = {
  "Akatsuki Blitzkampf": "abk",
  "DNF Duel": "dnf",
  "The King of Fighters XV": "kofxv",
  "Samurai Shodown": "samsho",
  "Street Fighter III: 3rd Strike": "sf3",
  "Street Fighter V": "sfv",
  "Persona 4: Arena Ultimax": "p4au",
  "Ultimate Marvel vs Capcom 3": "umvc3",
  "Street Fighter 6": "sf6",
  "Guilty Gear XX Accent Core Plus R": "acpr",
  "BlazBlue: Central Fiction": "bbcf",
  "Guilty Gear Xrd": "xrd",
  "Granblue Fantasy: Versus": "gbvs",
  "BlazBlue: Cross Tag Battle": "bbtag",
  "Under Night In-Birth": "unib",
  "Guilty Gear -Strive-": "strive",
  "Them's Fightin' Herds": "tfh",
  "Super Naruto: Clash of Ninja 4": "scon4",
  "Vampire Savior": "vsav",
  "Fate/Unlimited Codes": "f8uc",
  "Dissidia 012 Final Fantasy": "d012",
  "Tekken 8": "tkn8",
  "JoJo's Bizarre Adventure: All-Star Battle R": "asbr",
  "Virtua Fighter 5 R.E.V.O.": "vf5",
};
